.rpmi-menu {
    margin: 0;
    padding: 10px 15px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background-color: #4FE8D0;
}

.rpmi-content {
    padding: 2px;
    flex: 1;
    min-width: 0;
}

.rpmi-workspace {
    min-height: calc(100vh - 55px);
}

@media (min-width: 1100px) {
    .rpmi-content {
        width: 90%;
        margin: 0 auto;
    }
}

.rpmi-menu-btn {
    font-size: 26px;
    background: #fff0;
    border: none;
    padding: 0px 20px;
    color: #383838;

    &:hover {
        cursor: pointer;
        color: #fff;
    }
}

.rpmi-logout-btn {
    font-size: 1rem;
    background: #383838;
    border: none;
    float: right;
    padding: 6px 12px;
    border-radius: 4px;
    color: #fff;

    &:hover {
        cursor: pointer;
        background: #38383847;
    }
}

.rpmi-sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.2s;

    .close-btn {
        position: absolute;
        font-size: 36px;
        background: #fff0;
        border: none;
        left: 15px;
        top: 9px;

        &:hover {
            cursor: pointer;
            color: #4FE8D0;
        }
    }

}

.rpmi-sidenav-shadow {
    -webkit-box-shadow: -3px 2px 20px 6px #ad9a9a;
    box-shadow: -3px 2px 20px 6px #ad9a9a;
}

.rpmi-footer {
    margin: 0;
    margin-top: 10px;
    padding: 15px 20px;
    background-color: #7a7a7a2e;
    font-size: 0.8rem;
    color: #d3d3d3;
    ;
    width: 100%;
}

.loged-user {
    text-align: center;
    font-weight: bold;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #4FE8D0;
    color: black;
}
@import "./static/fontawesome/scss/fontawesome.scss";
@import "./static/fontawesome/scss/solid.scss";
@import "./static/fontawesome/scss/brands.scss";

@import "./static/scss/TimelineDemo.scss";

body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    background-color: #464444;
}

/* --------------------------------------
    ========= Primereact ================
----------------------------------------- */

.grid {
    margin: 0 !important;
    padding-top: 0.5rem;
}

.p-card .p-card-content {
    padding: 0 !important;
}

.p-panel {
    padding-top: 10px;
}

.p-tabview .p-tabview-panels {
    background: #292E36 !important;
    border: 1px solid #a5abb3 !important;
}

.p-panel-content,
.p-dialog-content {
    background: #292E36 !important;
    padding: 0 0.5rem 1.5rem 0.5rem !important;
}

.p-dialog-footer {
    padding: 0.8rem !important;
}

.p-fluid {
    width: 100%;
}

.p-datatable .p-datatable-tbody tr:nth-child(odd) {
    background-color: #32384e;
}

.p-timeline .p-timeline-event-connector {
    background-color: #898989 !important;
}

.p-steps .p-steps-item:before {
    border-top: 1px solid #bfbfbf !important;
}

.p-steps-number {
    background: #5eead4 !important;
    color: #343434de !important;
}

.p-divider-solid.p-divider-horizontal:before {
    border-color: #9b9898 !important;
}

@media (min-width: 1100px) {

    .p-panel-content,
    .p-dialog-content {
        padding: 0 1.5rem 1.5rem 1.5rem !important;
    }
}

@media (max-width: 1100px) {
    .p-dialog {
        margin: 0 !important;
    }

    .p-field {
        padding-top: 12px !important;
    }

}

/* --------------------------------------
    ========= RPMI ================
----------------------------------------- */

.rpmi-input {
    margin-top: 5px !important;
}

.rpmi-input-title {
    margin-left: 5px;
    font-weight: bold;
}

.rpmi-dialog-bottom-btn {
    float: right;
    width: 100%;
    text-align: right;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #7a7878;
}

.hidden-component {
    display: none;
}

.rpmi-link-btn {
    background-color: #4FE8D0;
    font-size: 1rem;
    padding: 10px;
    color: #303030;
    border-radius: 4px;

    &:hover {
        color: #838383;
    }

}

.rpmi-staus-form-info {
    text-align: right;
    padding-top: 26px !important;

    .p-badge {
        height: 2.7rem !important;
        line-height: 2.8rem !important;
    }

}